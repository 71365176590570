<template>
  <div class="app-page-wrapper pools">
    <div class="app-page-header">
      <div class="app-page-header-title app-page-title">Pools Overview</div>
      <div class="app-page-header-action">
        <!-- <button class="dropdown" @click="OpenIP()">Import Pool</button>
        <div
          class="dropdown-content-pool"
          :style="{ display: displayDropdown }"
        >
          <label for="pairAddress">New Pool:</label>
          <input
            type="text"
            placeholder="Write adddress of the pool here"
            name="pairAddress"
            id="pairAddress"
            v-model.trim="pairAdd"
            @keyup.enter="importPool(pairAdd)"
          />
        </div> -->
        <router-link :to="baseRoute">
          <button>+ New Position</button>
        </router-link>
      </div>
    </div>
    <div class="app-card">
      <div class="app-card-body">
        <div v-if="!displayWalletStatus" class="no-data-wrapper">
          <span>Your active liquidity positions will appear here.</span>
          <WalletConnect :showOnlyButton="true" />
        </div>
        <div v-else>
          <div
            v-if="!$store.state.pairsExistAndIs_SEL_Clicked"
            class="no-data-wrapper"
          >
            <span>Your active liquidity positions will appear here.</span>
            <span>Currently there are no pairs in existence.</span>
          </div>
          <div class="table-wrapper" v-else>
            <table>
              <tbody>
                <tr v-for="pair in $store.state.symLP" :key="pair.address">
                  <td>
                    {{ pair[0] }}
                    -
                    {{ pair[1] }}
                    LP
                  </td>
                  <td class="details">
                    <div v-if="pairValCanDisp(pair[4])">
                      {{ pair[4] }}
                    </div>
                    <div class="closed-svg" v-else>
                      <info-svg></info-svg> Closed
                    </div>
                  </td>
                  <td>
                    <router-link
                      :to="'/addLiquidity/' + pair[2] + '/' + pair[3]"
                    >
                      <button @click="addMoreLiquidity(pair[2], pair[3])">
                        Add More Liquidity
                      </button>
                    </router-link>
                  </td>
                  <td>
                    <router-link
                      v-if="pairValCanDisp(pair[4])"
                      :to="'/removeLiquidity/' + pair.address"
                    >
                      <button @click="remLiquidityPage(pair.address)">
                        Remove Liquidity
                      </button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="pool-info-wrapper">
      <div class="app-card">
        <div class="app-card-body">
          <a
            href="https://help.uniswap.org/en/articles/5391541-provide-liquidity-on-uniswap-v3"
            target="_blank"
          >
            Learn about providing liquidity ↗
          </a>
          <p>Check out our V2 LP walkthrough and migration guides.</p>
        </div>
      </div>
      <div class="app-card">
        <div class="app-card-body">
          <a href="https://info.uniswap.org/#/pools" target="_blank">
            Top pools ↗
          </a>
          <p>Explore Uniswap Analytics.</p>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as ethFunc from "../ethereumFunctions.js";
import InfoSvg from "../assets/svg/Info.vue";
import swal from "sweetalert";

export default {
  components: { InfoSvg },
  data() {
    return {
      displayDropdown: "none",
      // pairAdd: null,
      // impIn: false,
    };
  },
  methods: {
    ...mapActions({
      remLiquidityPage: "getDataForLiqRemPage",
    }),
    // importPool(pairAdd) {
    //   this.impIn = true;
    //   this.OpenIP();
    //   // this.$store.state.allPairs.unshift(pairAdd);
    //   this.$store.dispatch("registerExistingLiquidity", 1);
    // },
    OpenIP() {
      if (this.displayDropdown === "none") {
        this.displayDropdown = "block";
      } else {
        this.displayDropdown = "none";
      }
    },
    pairValCanDisp(val) {
      if (Number(val) < 1e-14) {
        return false;
      }
      return true;
    },
    async addMoreLiquidity(token0Address, token1Address) {
      // console.log("het");
      this.$store.state.addLiquidity.dispPool = true;
      await ethFunc
        .getBalanceandSymbol(this.$store.state.account0, token0Address, true)
        .then((data) => {
          this.liqTokenSymbolVal[0] = data.symbol;
        });
      await ethFunc
        .getBalanceandSymbol(this.$store.state.account0, token1Address, true)
        .then((data) => {
          this.liqTokenSymbolVal[1] = data.symbol;
        });
      this.liqDialogVal.DialnumAdd[0] = token0Address;
      this.liqDialogVal.DialnumAdd[1] = token1Address;
      this.$store.dispatch("displayReservesPool");
    },
  },
  computed: {
    ...mapGetters({
      displayWalletStatus: "displayWalletStatus",
      liqTokenSymbolVal: "getLiqTokenSymbol",
      liqDialogVal: "getLiqDialog",
      baseRoute: "getBaseLiqRoute",
    }),
  },
  beforeRouteLeave(to, _, next) {
    if (
      to.path.search("addLiquidity") === 1 ||
      to.path.search("removeLiquidity") === 1
    ) {
      if (this.displayWalletStatus == true) {
        next();
      } else {
        next(false);
        swal("Alert", "Please connect to a wallet first!", "warning");
      }
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.app-card {
  width: 100%;
}

.no-data-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  min-height: 200px;
}

.closed-svg {
  display: flex;
  width: 3.7rem;
  align-items: center;
  justify-content: space-between;
}

.table-wrapper {
  margin: -1.5rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-radius: var(--element-border-radius);
  overflow: hidden;
}

table td {
  padding: 1rem 8px;
  border-bottom: var(--element-border);
  border-top: var(--element-border);
}

table td button {
  height: 32px;
  font-size: 0.8rem;
  margin: 0 0 0 auto;
}

table td:first-child {
  padding-left: 1rem;
}

table td:last-child {
  padding-right: 1rem;
}

table td:nth-last-child(1),
table td:nth-last-child(2) {
  width: 165px;
}
</style>
